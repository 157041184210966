import { DomainConfig, PostListOrderBy } from '@creator/sdk/modules/config/config.model';
import store from '@src/store';
import { getDomainName, getFirebaseStorageBucket, getOperatorMobileApp } from '@src/config';
import creatorSdk from '@src/services/creator-sdk';
import { AuthProviderId, OneTapAuthConfig } from '@creator/sdk/modules/account/account.model';
import { getGoogleProviderClientId } from '@src/config';
import { isMobile } from '@src/utils/utils';

export function getDomainConfig(): DomainConfig {
    return store.getState().config.domainConfig as DomainConfig; // we do not render the app untill the config is loaded.. so its safe to return as 'DomainConfig'
}

export function getDefaultDomainConfig(): DomainConfig {
    return {
        defaultTokenName: '',
        domainDisplayName: 'dɘWeb',
        logoLink: '/',
        signInProviders: ['TWITTER', 'GOOGLE', 'GITHUB'],
        privacyPolicyLink: '',
        termsOfUseLink: '',
        faqLink: '',
        disableCreateToken: false,
        enableForumPicker: false,
        mobileApp: undefined
    };
}

export function getDomainConfigValue<K extends keyof DomainConfig>(key: K): DomainConfig[K] {
    const config = getDomainConfig();
    return config[key];
}

//Auth providers list
export function getAuthProviderIds(): AuthProviderId[] {
    const signInProviders = getDomainConfigValue('signInProviders');
    const authProviderIds = signInProviders.map(signInProvider =>
        creatorSdk.configModule.helpers.getAuthProviderIdBySignInProvider(signInProvider));
    return authProviderIds;
}

//Get google one tap outh config
export function getGoogleOneTapToAuthConfig(): OneTapAuthConfig {
    return creatorSdk.configModule.helpers.getGoogleOneTapToAuthConfig(getGoogleProviderClientId());
}

export function getLogoSrc(): string {
    return getStorageBucketFile('logo.png');
}

export function getLogoDarkSrc(): string {
    return getStorageBucketFile('logo-dark.png');
}

export function getStorageBucketFile(filename: string): string {
    const storageBucket = getFirebaseStorageBucket();
    const domainName = getDomainName();
    return `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/${domainName}%2F${filename}?alt=media`;
}

export function getPostListDefaultOrderBy(): PostListOrderBy {
    const config = getDomainConfig();
    return config.upvote.postListDefaultOrderBy;
}

export function getMessageBoardReactionNames(): string[] {
    return ['thumb-up', 'thumb-down'];
}

export function getDomainMobileApp() {
    return getDomainConfigValue('mobileApp');
}

export function getDownloadAppUrl(tokenName = '', postId = ''): string {
    const defaultMobileApp = getOperatorMobileApp();
    const mobileApp = getDomainMobileApp() || defaultMobileApp;

    const { dynamicLink, apn, announcementUrl, isi, ibi } = mobileApp;

    const domainName = getDomainName();

    let link = `${announcementUrl}?install__domain=${domainName}`;
    if (tokenName) link += `__community=${tokenName}`;
    if (postId) link += `__post=${postId}`;


    return `${dynamicLink}/?link=${encodeURIComponent(link)}&apn=${apn}&isi=${isi}&ibi=${ibi}`;
}

export function getUrlMappingTokenRouteBasePaths() {
    return getDomainConfigValue('urlMapping')?.tokenRouteBasePaths || ['/'];
}

export function getUrlMappingToken(tokenName: string) {
    const urlMapping = getDomainConfigValue('urlMapping');
    if (!urlMapping) return;

    const { tokens = {} } = urlMapping;
    return tokens[tokenName];
}

export function getTokenSlugUrl(tokenName: string) {
    const token = getUrlMappingToken(tokenName);
    if (!token) return;
    return token.urlSlug;
}

// Return url slug if available, if not return tokenName, usefull for links
export function ensureTokenUrl(tokenName: string, includeSection = true) {
    const token = getUrlMappingToken(tokenName);
    if (!token) return tokenName;
    const { urlSlug, section } = token;
    if (section && includeSection) return `${section}/${urlSlug}`;
    return urlSlug;
}

// ensure token name from url slug or token name. eg - seattle-kraken -> NHLKRAK
export function ensureTokenName(tokenNameOrUrlSlug: string) {
    const urlMapping = getDomainConfigValue('urlMapping');
    if (!urlMapping) return tokenNameOrUrlSlug;
    const { tokens = {} } = urlMapping;
    return Object.keys(tokens).find(tokenName => tokens[tokenName].urlSlug === tokenNameOrUrlSlug) || tokenNameOrUrlSlug;
}

export function getYahooAdByLocation(location: string) {
    const yahooAd = getDomainConfigValue('yahooAd');
    if (!yahooAd) return null;
    const { ads, locations } = yahooAd;

    return ads.find(ad => ad.divId === locations[location]);
}

// ie. return main_mw_top_center or main_dt_top_center based on device
export function getYahooAdLocatioByTokenName(tokenName: string, position: string) {
    const deviceType = isMobile() ? 'mw' : 'dt';
    const mainTargeting = getYahooAdTargetingByValue('main');
    if (!mainTargeting) return;

    const placementType = mainTargeting.tokenName === tokenName ? 'main' : 'ros';

    return `${placementType}_${deviceType}_${position}`;
}

export function getYahooVideoAdByLocation(location: string) {
    const yahooAd = getDomainConfigValue('yahooAd');
    if (!yahooAd) return null;
    const { videoAdUrls = {} } = yahooAd;
    return videoAdUrls[location] || null;
}

export function getYahooAdTargeting() {
    const yahooAd = getDomainConfigValue('yahooAd');
    if (!yahooAd) return [];
    return yahooAd.targeting || [];
}

export function getYahooAdTargetingByTokenName(tokenName: string) {
    const targeting = getYahooAdTargeting();
    const res = targeting.find(targeting => targeting.tokenName === tokenName);
    if (!res) return;
    return { key: res.key, value: res.value };
}

export function getYahooAdTargetingByValue(value: string) {
    const targeting = getYahooAdTargeting();
    return targeting.find(targeting => targeting.value === value);
}

/**
 * Constructs the ad tag URL with cust_params for Yahoo video ads.
 * @param baseUrl - The base ad URL (e.g., from getYahooVideoAdByLocation).
 * @param adTargeting - Optional key-value pair for cust_params.
 * @returns The constructed ad tag URL with cust_params, or null if baseUrl is invalid.
 */
export function getYahooVideoAdTagUrl(baseUrl: string, adTargeting?: { key: string; value: string }) {
    const url = new URL(baseUrl);

    // Add cust_params if adTargeting is provided
    if (adTargeting) {
        const { key, value } = adTargeting;
        url.searchParams.set('cust_params', `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }

    return url.toString();
}

export function getTokenNameOutOfUrlPath(urlPath: string): string {
    const tokenBasePaths = getUrlMappingTokenRouteBasePaths();
    const parts = urlPath.split('/');
    const firstPart = parts[0] ? parts[0] : parts[1];
    if (firstPart === 'creator' || firstPart === 'bbs') return '';

    if (tokenBasePaths.includes(`/${firstPart}`)) return ensureTokenName(parts[2]);

    return ensureTokenName(firstPart);
}
