import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import './styles.css';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useStoreActions, useStoreState } from './model/hooks';
import { getUserId } from './model/user/helpers';
import { loadLanguage } from './model/config/actions';
import { getDomainConfigValue } from './model/config/helpers';
import { getQueryParam, getUrlPath, removeQueryParamFromUrl } from './utils/url-utils/url-utils';
import ScrollToTop from './basic-components/ScrollToTop/ScrollToTop';
import { getDomainName } from './config';
import { ModalProvider } from './context/modal-context';
import storageService from './services/storage-service';
import { Routes } from './Routes';
import OneTapOnly from './components/Auth/OneTapOnly/OneTapOnly';
import SystemBackdoor from './components/SystemBackdoor/SystemBackdoor';
import PlaywireAd from './components/PlaywireAd/PlaywireAd';
import { initializeGTM } from './services/gtmService';
import { DomainConfig } from '@creator/sdk/modules/config/config.model';
import { processSSRData } from './model/ssr-data/ssr-data';

export default function App() {
    const domainName = getDomainName();

    const authenticateOnStartup = useStoreActions(actions => actions.user.authenticateOnStartup);
    const setIsDarkModeOn = useStoreActions(actions => actions.appearanceSettings.seIsDarkModeOn);
    const loadDomaineConfig = useStoreActions(actions => actions.config.loadDomainConfig);
    const loadDomainMainMenu = useStoreActions(actions => actions.config.loadDomainMainMenu);
    const loadUser = useStoreActions(actions => actions.user.loadUser);
    const loadRTBPrice = useStoreActions(actions => actions.tokenPrice.loadRTBPrice);
    const loadMyUserInfo = useStoreActions(actions => actions.user.loadMyUserInfo);
    const loadDomainPublicData = useStoreActions(actions => actions.domain.loadPublicData);
    const loadCommunityDefaults = useStoreActions(actions => actions.publicSettings.loadCommunityDefaults);
    const loadDomainsSymbols = useStoreActions(actions => actions.publicSettings.loadDomainsSymbols);
    const loadExternalArticleDomain = useStoreActions(actions => actions.publicSettings.loadExternalArticleDomain);
    const isLoggedIn = useStoreState(state => state.user.isLoggedIn);
    const isAuthenticating = useStoreState(state => state.user.isAuthenticating);
    const domainConfig = useStoreState(state => state.config.domainConfig);
    const refBy = getQueryParam('refBy') || '';
    const refFrom = getQueryParam('refFrom') || '';

    const [isAppReady, setIsAppReady] = useState(false);

    useEffect(() => {
        console.time('isAppReady');
        processSSRData();
        initDomainConfig();
        loadDomainMainMenu();
        // injectDomainCustomCss();
        loadDomainsSymbols();
        loadExternalArticleDomain();
        loadCommunityDefaults();
        authenticateOnStartup();
        loadDomainPublicData(domainName);
        loadRTBPrice();
        if (refBy || refFrom)
            setRefByAndForm();
    }, []);

    useEffect(() => {
        if (!isAppReady) return;
        // remove loading
        document.documentElement.classList.remove('loading');
        console.timeEnd('isAppReady');
    }, [isAppReady]);

    storageService.localStorage.set('isLandingPageVisit', 'true');
    const firstEverVisitPath = storageService.localStorage.get('firstEverVisitPath');
    if (!firstEverVisitPath) {
        window.isFirstEverVisit = true;
        storageService.localStorage.set('firstEverVisitPath', getUrlPath());
    }

    const isFirstPathChangeUpdate = useRef(true); // https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
    function onPathChange(): void {
        // first route change
        if (isFirstPathChangeUpdate.current) {
            isFirstPathChangeUpdate.current = false;
            return;
        }

        storageService.localStorage.set('isLandingPageVisit', 'false');
    }

    useEffect(() => {
        if (!isLoggedIn) return;

        const userId = getUserId();
        loadUser(userId);
        loadMyUserInfo();
    }, [isLoggedIn]);

    useEffect(() => {
        if (isAuthenticating !== false) return; // false means we done, we are after operations
        if (refBy || refFrom)
            setRefByAndForm();
    }, [isAuthenticating]);

    async function initDomainConfig() {
        const [domainConfig] = await Promise.all([loadDomaineConfig(), initI18n()]);
        init(domainConfig);
    }

    function setRefByAndForm(): void {
        if (refBy)
            storageService.localStorage.set('refBy', refBy);
        if (refFrom)
            storageService.localStorage.set('refFrom', refFrom);

        removeQueryParamFromUrl('refBy');
        removeQueryParamFromUrl('refFrom');
    }


    // async function injectDomainCustomCss(): Promise<void> {
    //     const css = await loadDomainCustomCss();
    //     injectStyle(css);
    // }

    async function init(domainConfig: DomainConfig) {
        if (domainConfig.googleTagManager)
            initializeGTM(domainConfig.googleTagManager.id, domainConfig.googleTagManager.enableDebug);

        const isDarkModeOnLocalStorage = storageService.localStorage.get('isDarkModeOn');
        const isDarkModeOn = isDarkModeOnLocalStorage ? isDarkModeOnLocalStorage === 'true' : false;
        setIsDarkModeOn(isDarkModeOn);

        setIsAppReady(true);
    }

    async function initI18n() {
        const lang = 'en';
        const langResource = await loadLanguage(lang);
        const i18nConfig = {
            fallbackLng: 'en',
            debug: false,
            lng: lang,
            resources: {
                [lang]: {
                    translation: langResource
                }
            },
            interpolation: {
                escapeValue: false // React already escapes by default
            }
        };
        await i18n.use(initReactI18next).init(i18nConfig);
    }

    if (!isAppReady) return <div></div>; // TODO - use <Suspend />

    return (
        <Fragment>
            <Router basename="/">
                <ModalProvider>
                    <ScrollToTop />
                    <OneTapOnly />
                    <SystemBackdoor isAppReady={isAppReady} />
                    <Routes onPathChange={onPathChange} />
                </ModalProvider>
            </Router>
            <div id="modal-root"></div>
        </Fragment>
    );
}
