import React, { FC, Fragment, memo, ReactNode, useCallback, useMemo, useState } from 'react';
import { useStoreState } from '@src/model/hooks';
import { ensurePostUrl, isPostDeleted, parsePostContent } from '@src/model/upvote/helpers';
import { useHistory } from 'react-router-dom';
import { ensureTokenUrl } from '@src/model/config/helpers';
import { propsEqualityFn, storeEqualityFn } from '@src/utils/object-utils/object-utils';
import PostStatsAndReactions from '../PostStatsAndReactions/PostStatsAndReactions';
import Text from '@creator/ui/components/Text/Text';
import { convertFirebaseTimestampToDate, formatDate } from '@creator/ui/utils/date-utils';
import UserDisplayNameWithLink from '@src/basic-components/UserDisplayName/UserDisplayNameWithLink';
import { useTranslation } from 'react-i18next';
import { cn } from '@creator/ui/utils/ui';
import { getMainImageFromBlocks, summarizeBlocksContent } from '@src/utils/editorjs-utils/editorjs-utils';
import PostMenuPopover from '../PostMenuPopover/PostMenuPopover';
import Image, { ImageProps } from '@creator/ui/components/Image/Image';
import { mergeProps } from '@creator/ui/utils/merge-props/merge-props';
import QuickCommentInput from '@src/basic-components/CommentInput/QuickCmmentInput';

const FALLBACK_SOLID_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAAAW9JREFUeF7t1UENACAQxEDwigRcIBoSVMyjp6Bps7m59rmjYwzMgjAtPkhBrB4FwXoUpCCaAYynH1IQzACG00IKghnAcFpIQTADGE4LKQhmAMNpIQXBDGA4LaQgmAEMp4UUBDOA4bSQgmAGMJwWUhDMAIbTQgqCGcBwWkhBMAMYTgspCGYAw2khBcEMYDgtpCCYAQynhRQEM4DhtJCCYAYwnBZSEMwAhtNCCoIZwHBaSEEwAxhOCykIZgDDaSEFwQxgOC2kIJgBDKeFFAQzgOG0kIJgBjCcFlIQzACG00IKghnAcFpIQTADGE4LKQhmAMNpIQXBDGA4LaQgmAEMp4UUBDOA4bSQgmAGMJwWUhDMAIbTQgqCGcBwWkhBMAMYTgspCGYAw2khBcEMYDgtpCCYAQynhRQEM4DhtJCCYAYwnBZSEMwAhtNCCoIZwHBaSEEwAxhOCykIZgDDaSEFwQxgOC2kIJgBDKeFFAQzgOE8BMzurYYPrzkAAAAASUVORK5CYII='

export interface PostCardProps {
    className?: string;
    tokenName: string;
    postId: string;
    children?: ReactNode;
    showMainTextContent?: boolean;
    showQuickCommentInput?: boolean;
    mainImageProps?: Partial<ImageProps>;
    isLoading?: boolean;
}

const PostCard: FC<PostCardProps> = props => {
    const { tokenName, postId, className, showMainTextContent, isLoading } = props;

    // Switch to using Jotai for state management when its available
    // const [post] = useAtom(useMemo(() => getPostAtom(postId), [postId]));
    const post = useStoreState(state => state.upvote.getPost(postId), storeEqualityFn);
    const postContent = useMemo(() => post && parsePostContent(post), [post]);

    const history = useHistory();
    const { t } = useTranslation();

    const onPostClick = useCallback(() => {
        history.push(`/${ensureTokenUrl(tokenName)}/${ensurePostUrl(postId)}`);
    }, [postId]);

    function getPostCreatedTime() {
        if (!post) return '';
        const { createdAt } = post;
        const _date = convertFirebaseTimestampToDate(createdAt);
        return formatDate(_date);
    }

    function getMainText() {
        if (!showMainTextContent) return null;
        if (isLoading) return 'loading';
        if (!postContent) return null;
        return summarizeBlocksContent(postContent?.blocks);
    }

    function renderMainTextContent() {
        const text = getMainText();
        if (!text) return null;

        return (
            <div className="h-8 text-xs cursor-pointer line-clamp-2">
                <Text loadingSkeletonProps={{ count: 2 }} isLoading={isLoading} enableMultiline={true}>{text.slice(0, 400)}</Text>
            </div>
        );
    }

    const renderPostCardFooter = () => {
        return (
            <PostStatsAndReactions
                className=""
                tokenName={tokenName}
                isDisabled={isPostDeleted(postId)}
                postId={postId}
            />
        );
    };

    function renderPartnerLabel() {
        if (!post) return null;
        const { createdByWhitelistedUser } = post;
        if (!createdByWhitelistedUser) return null;

        return (
            <Fragment>
                <div className="w-[1px] bg-gray-950 dark:bg-gray-50 h-3 shrink-0"></div>
                <Text className="text-primary-400">{t('postCard.partnerLabel')}</Text>
            </Fragment>
        );
    }

    function renderUserDisplayNameWithLink() {
        return (
            <UserDisplayNameWithLink
                slugUrl={post?.publisherSlugUrl}
                tokenName={tokenName}
                profileName={post?.publisherProfileName}
                displayName={post?.publisherName || ''}
                userDisplayNameProps={{ className: '' }} />
        );
    }

    function renderByline() {
        return (
            <div className="flex items-center h-3 space-x-2 text-xs">
                {renderUserDisplayNameWithLink()}
                <div className="w-[1px] bg-gray-950 dark:bg-gray-50 h-full shrink-0"></div>
                <Text className="">{getPostCreatedTime()}</Text>
                {renderPartnerLabel()}
            </div>
        );
    }

    function renderMainImage() {
        const { mainImageProps = {} } = props;
        const image = getMainImageFromBlocks(postContent?.blocks || []);

        const _props = mergeProps<[Partial<ImageProps>, Partial<ImageProps>]>({
            className: 'aspect-[1/1] overflow-hidden',
            src: image || FALLBACK_SOLID_IMAGE,
            fallbackSrc: FALLBACK_SOLID_IMAGE,
            isLoading
        }, mainImageProps);
        return <Image {..._props} />;
    }

    function renderQuickCommentInput() {
        const { showQuickCommentInput = false } = props;
        if (!postId || !showQuickCommentInput) return null;

        return <QuickCommentInput tokenName={tokenName} postId={postId} />;
    }

    return (
        <div className={cn('relative flex flex-col space-y-3 cursor-pointer', isPostDeleted(postId) ? 'opacity-40' : '', className)} onClick={onPostClick} >
            {renderMainImage()}
            <div className="pl-4 pr-2 space-y-3 lg:px-0">
                <div className="space-y-2" >
                    <div className="flex items-start justify-between">
                        <Text enableMultiline={true} as="h3" className="h-10 font-bold line-clamp-2">{post?.title}</Text>
                        <PostMenuPopover postId={postId} tokenName={tokenName} />
                    </div>
                    {renderByline()}
                </div>
                {renderMainTextContent()}
                {renderQuickCommentInput()}
                {renderPostCardFooter()}
            </div>
        </div>
    );
};

export default memo(PostCard, propsEqualityFn);