import React, { FC, memo } from 'react';
import { cn } from '@creator/ui/utils/ui';
import PostCard from '@src/components/Post/PostCard/PostCard';

interface MainPostCardProps {
    postId: string;
    tokenName: string;
    isLoading?: boolean;
}

const MainPostCard: FC<MainPostCardProps> = props => {
    const { postId, tokenName, isLoading } = props;
    return (
        <PostCard
            isLoading={isLoading}
            showQuickCommentInput={true}
            showMainTextContent={true}
            tokenName={tokenName}
            mainImageProps={{
                className: 'lg:aspect-[2/1]',
            }}
            className={cn('bg-transparent dark:bg-transparent lg:col-span-2')}
            postId={postId}
        />
    );
};

export default memo(MainPostCard);
