import React, { FC, PropsWithChildren } from 'react';
import Text from '@creator/ui/components/Text/Text';
import { noop } from '@src/utils/utils';
import Image from '@creator/ui/components/Image/Image';
import { cn } from '@creator/ui/utils/ui';
export interface ExternalLinkPreviewProps {
    className?: string;
    caption?: string;
    title?: string;
    hostname?: string;
    image?: string;
    infoClassName?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const ExternalLinkPreview: FC<PropsWithChildren<ExternalLinkPreviewProps>> = props => {
    const { onClick = noop, className = '', caption } = props;

    function getInfoClassName() {
        const { infoClassName = '' } = props;
        const _base = 'px-4 py-2 transition bg-gray-100 dark:bg-gray-800 dark:text-gray-50';
        return cn(_base, infoClassName);
    }

    function renderImage() {
        const { image } = props;
        if (!image) return null;

        return (
            <Image className="overflow-hidden aspect-video" src={image} alt={caption} />
        );
    }

    function renderTitle() {
        const { title } = props;
        if (!title) return null;

        return <Text enableMultiline={true} className="block text-sm font-bold text-gray-900 transition line-clamp-1 text-ellipsis dark:text-gray-50">{title}</Text>;
    }

    function renderHostname() {
        const { hostname } = props;
        if (!hostname) return null;

        return <Text className="block text-xs mb-0.5 text-gray-500 uppercase">{hostname}</Text>;
    }

    function renderCaption() {
        return <Text enableMultiline={true} className="block text-sm text-gray-500 line-clamp-1">{caption}</Text>;
    }

    function renderInfo() {
        return (
            <div className={getInfoClassName()}>
                {renderHostname()}
                {renderTitle()}
                {renderCaption()}
            </div>
        );
    }
    return (
        <div className={`cursor-pointer rounded-lg overflow-hidden relative ${className}`} onClick={onClick}>
            {renderImage()}
            {renderInfo()}
        </div>
    );
};

export default ExternalLinkPreview;
